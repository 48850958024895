<template>
  <div class="whislistcontainer mt-5 mx-auto w-full">
    <div class="vx-row">
      <div class="vx-col w-full">
        <WhitelistedIPList />
      </div>
    </div>
  </div>
</template>

<script>
import WhitelistedIPList from "./WhitelistedIPList";
export default {
  components: {
    WhitelistedIPList
  }
};
</script>
