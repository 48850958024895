var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white mt-4"},[_c('div',{staticClass:"flex mb-2"},[_c('div',{staticClass:"w-1/5 flex justify-center pt-4 lg:ml-0 md:ml-0 ml-4"},[_c('vs-select',{staticClass:"selectExample",attrs:{"placeholder":"Filter","disabled":_vm.searchTerm.length > 0},model:{value:(_vm.filterBy),callback:function ($$v) {_vm.filterBy=$$v},expression:"filterBy"}},_vm._l((_vm.filterOptions),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.value,"text":item.text}})}),1)],1),_c('MemberSearch',{staticClass:"w-4/5",attrs:{"searchTerm":_vm.searchTerm,"dataArray":_vm.allWhitelistedIPs,"keys":_vm.searchKey},on:{"searchResult":_vm.filterResults}},[_c('template',{slot:"searchBar"},[_c('vs-input',{staticClass:"w-full pt-4 px-4",attrs:{"icon-pack":"feather","icon":"icon-search","icon-after":"","placeholder":"Search","vs-placeholder":"Nombre"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],2)],1),_c('vx-card',{attrs:{"title":"Whitelisted IPs"}},[_c('div',{attrs:{"slot":"actions"},slot:"actions"},[_c('vs-button',{attrs:{"color":"primary","type":"filled"},on:{"click":_vm.handleAdd}},[_vm._v("+ ADD NEW IP")]),_c('WhitelistSidebar',{attrs:{"isSidebarActive":_vm.isSidebar,"data":_vm.updateData},on:{"closeSidebar":_vm.toggleWhitelistSidebar}})],1),_c('div',{staticClass:"mt-4",attrs:{"slot":"no-body"},slot:"no-body"},[_c('vs-table',{staticClass:"table-dark-inverted",attrs:{"data":_vm.tableData},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":data[indextr].ipAddress}},[_c('span',[_vm._v(_vm._s(data[indextr].ipAddress))])]),_c('vs-td',{attrs:{"data":data[indextr].status}},[_c('span',{staticClass:"flex items-center px-2 py-1 rounded"},[_c('div',{staticClass:"h-3 w-3 rounded-full mr-2",class:{
                    'bg-success': +data[indextr].status,
                    'bg-danger': !data[indextr].status,
                  }}),_vm._v(" "+_vm._s(data[indextr].status ? "Activated" : "Deactivated")+" ")])]),_c('vs-td',{attrs:{"data":data[indextr].createdBy}},[_vm._v(_vm._s(data[indextr].createdBy))]),_c('vs-td',{attrs:{"data":data[indextr].createdAt}},[_c('span',[_vm._v(" "+_vm._s(new Date(data[indextr].createdAt).toLocaleString("en-IN"))+" ")])]),_c('vs-td',{attrs:{"data":data[indextr]._id}},[_c('vs-dropdown',{attrs:{"vs-trigger-click":""}},[_c('a',{staticClass:"text-black ml-4 flex items-center cursor-pointer"},[_c('i',{staticClass:"material-icons"},[_vm._v("more_vert")])]),_c('vs-dropdown-menu',[_vm._l((_vm.options),function(option,index){return _c('vs-dropdown-item',{key:`${index}-${option.name}`,on:{"click":function($event){return _vm.handleOption(data[indextr], option.name)}}},[_c('div',{staticClass:"flex"},[_c('feather-icon',{attrs:{"icon":option.icon,"svgClasses":"h-4 w-4"}}),_c('p',{staticClass:"ml-2"},[_vm._v(_vm._s(option.name))])],1)])}),_c('vs-dropdown-item',{on:{"click":function($event){return _vm.handleStatus(data[indextr])}}},[_c('div',{staticClass:"flex"},[_c('feather-icon',{attrs:{"icon":"PowerIcon","svgClasses":{
                          'h-4 w-4': true,
                          'text-success': !data[indextr].status,
                          'text-danger': data[indextr].status,
                        }}}),_c('p',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.optionName(data[indextr].status))+" ")])],1)])],2)],1)],1)],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("IP ADDRESS")]),_c('vs-th',[_vm._v("STATUS")]),_c('vs-th',[_vm._v("CREATED BY")]),_c('vs-th',[_vm._v("CREATED DATE")]),_c('vs-th',[_vm._v("ACTIONS")])],1)],2)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataCount > 1),expression:"dataCount > 1"}],staticClass:"mt-4"},[_c('vs-pagination',{attrs:{"total":_vm.dataCount},model:{value:(_vm.dataPage),callback:function ($$v) {_vm.dataPage=$$v},expression:"dataPage"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }