<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="sidebarx"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="flex px-4 sidebar-topfixpart bg-primary">
      <feather-icon
        icon="ChevronLeftIcon"
        @click="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
      <vs-button class="h2-class pd-10 cust-remove-boxsh">{{
        isInsert ? "ADD NEW IP" : "UPDATE IP"
      }}</vs-button>
    </div>

    <div class="Main-inviteallsection mt-16">
      <div class="input-invite-sec pd-10" v-if="isInsert">
        <vs-checkbox v-model="isRange" class="mt-6">Is CIDR ?</vs-checkbox>
      </div>
      <div class="input-invite-sec pd-10">
        <vs-input
          class="inputx w-full"
          :placeholder="ipPlaceholder"
          v-model="ipAddress"
        />
      </div>
      <vs-button
        :disabled="isIPEmpty"
        color="primary"
        type="filled"
        class="w-full block addPost-sent-invites h2-class"
        @click="handleClick"
      >
        <span class="h3-upp-class h2-class">
          {{ isInsert ? "ADD NEW IP" : "UPDATE IP" }}
        </span>
      </vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import "@/Core/Views/Commoncss/componentCss/allSidebar.css";
export default {
  components: {},
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) {
        this.ipAddress = "";
      } else {
        this.isInsert ? this.initValues() : this.defaultValues(this.data);
      }
    },
    $route() {
      this.isSidebarActiveLocal = false;
      this.$emit("closeSidebar");
    }
  },
  data() {
    return {
      isRange: false,

      ipAddress: ""
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    isInsert() {
      return Object.entries(this.data).length === 0;
    },
    isIPEmpty() {
      return this.ipAddress == "";
    },
    ipPlaceholder() {
      return this.isRange ? "CIDR" : "I.P Address";
    }
  },
  methods: {
    initValues() {
      this.ipAddress = "";
    },
    defaultValues({ ip }) {
      this.ipAddress = ip;
    },
    handleClick() {
      if (this.isInsert) {
        this.handleInsert();
      } else {
        this.handleUpdate();
      }
    },
    async handleUpdate() {
      if (!this.validateIP()) {
        await this.alert("error", "Please insert a vaild IP Address");
        return;
      }
      this.$vs.loading();
      const payload = {
        id: this.data.id,
        newIPAddress: this.ipAddress,
        author: this.$store.state.user.username
      };
      const data = await this.$store.dispatch("whitelist/updateIP", payload);
      if (data) {
        this.$vs.loading.close();
        this.$emit("closeSidebar");
        await this.alert("success", "IP Updated Successfully");
      }
    },
    async handleInsert() {
      if (!this.isRange && !this.validateIP()) {
        await this.alert("error", "Please insert a vaild IP Address");
        return;
      }
      if (!this.isRange && !this.validateCIDR) {
        return  await this.alert("error", "Please insert a vaild CIDR");
      }
   

      const payload = {
        status: true,
        author: this.$store.state.user.username
      };
      if (this.isRange) {
      
        const IPCIDR = require("ip-cidr");
        const cidr = new IPCIDR(this.ipAddress);
        console.log(cidr,"cidrcidr")
        if(cidr._isValid==false){
          return this.alert("error", "Please insert a vaild CIDR");
        }
        payload.isRange = true;
        payload.ips = cidr.toArray();
        
      } else {
        payload.ip = this.ipAddress;
      }
      this.$vs.loading();

      const data = await this.$store.dispatch("whitelist/addIP", payload);
      if (data) {
        this.$vs.loading.close();
        this.$emit("closeSidebar");
        await this.alert("success", "IP Added Successfully");
      }
    },
    validateIP() {
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          this.ipAddress
        )
      ) {
        return true;
      }
      return false;
    },
    validateCIDR() {
      
      const IPCIDR = require("ip-cidr");
      const cidr = new IPCIDR(this.ipAddress);
      return cidr.isValid();
    },
    alert(type, text) {
       this.$swal({
        icon: type,
        title: text,
        showConfirmButton: false,
        timer: 1000
      });
    }
  }
};
</script>
