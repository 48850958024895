<template>
  <div class="bg-white mt-4">
    <div class="flex mb-2">
      <div class="w-1/5 flex justify-center pt-4 lg:ml-0 md:ml-0 ml-4">
        <vs-select
          class="selectExample"
          placeholder="Filter"
          v-model="filterBy"
          :disabled="searchTerm.length > 0"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in filterOptions"
          />
        </vs-select>
      </div>
      <MemberSearch
        :searchTerm="searchTerm"
        :dataArray="allWhitelistedIPs"
        :keys="searchKey"
        @searchResult="filterResults"
        class="w-4/5"
      >
        <template slot="searchBar">
          <vs-input
            icon-pack="feather"
            icon="icon-search"
            icon-after
            placeholder="Search"
            vs-placeholder="Nombre"
            class="w-full pt-4 px-4"
            v-model="searchTerm"
          />
        </template>
      </MemberSearch>
    </div>
    <vx-card title="Whitelisted IPs">
      <div slot="actions">
        <vs-button color="primary" type="filled" @click="handleAdd"
          >+ ADD NEW IP</vs-button
        >
        <WhitelistSidebar
          :isSidebarActive="isSidebar"
          @closeSidebar="toggleWhitelistSidebar"
          :data="updateData"
        />
      </div>
      <div slot="no-body" class="mt-4">
        <vs-table :data="tableData" class="table-dark-inverted">
          <template slot="thead">
            <vs-th>IP ADDRESS</vs-th>
            <vs-th>STATUS</vs-th>
            <vs-th>CREATED BY</vs-th>
            <vs-th>CREATED DATE</vs-th>
            <vs-th>ACTIONS</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].ipAddress">
                <span>{{ data[indextr].ipAddress }}</span>
              </vs-td>
              <vs-td :data="data[indextr].status">
                <span class="flex items-center px-2 py-1 rounded">
                  <div
                    class="h-3 w-3 rounded-full mr-2"
                    :class="{
                      'bg-success': +data[indextr].status,
                      'bg-danger': !data[indextr].status,
                    }"
                  ></div>
                  {{ data[indextr].status ? "Activated" : "Deactivated" }}
                </span>
              </vs-td>
              <vs-td :data="data[indextr].createdBy">{{
                data[indextr].createdBy
              }}</vs-td>
              <vs-td :data="data[indextr].createdAt">
                <span>
                  {{
                    new Date(data[indextr].createdAt).toLocaleString("en-IN")
                  }}
                </span>
              </vs-td>
              <vs-td :data="data[indextr]._id">
                <vs-dropdown vs-trigger-click>
                  <a class="text-black ml-4 flex items-center cursor-pointer">
                    <i class="material-icons">more_vert</i>
                  </a>

                  <vs-dropdown-menu>
                    <vs-dropdown-item
                      v-for="(option, index) in options"
                      :key="`${index}-${option.name}`"
                      @click="handleOption(data[indextr], option.name)"
                    >
                      <div class="flex">
                        <feather-icon
                          :icon="option.icon"
                          svgClasses="h-4 w-4"
                        />
                        <p class="ml-2">{{ option.name }}</p>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="handleStatus(data[indextr])">
                      <div class="flex">
                        <feather-icon
                          icon="PowerIcon"
                          :svgClasses="{
                            'h-4 w-4': true,
                            'text-success': !data[indextr].status,
                            'text-danger': data[indextr].status,
                          }"
                        />
                        <p class="ml-2">
                          {{ optionName(data[indextr].status) }}
                        </p>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
      <div slot="footer">
        <div class="mt-4" v-show="dataCount > 1">
          <vs-pagination :total="dataCount" v-model="dataPage"></vs-pagination>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WhitelistSidebar from "./WhitelistSidebar";
import MemberSearch from "@/Core/Views/CommonPlugins/member-search/MemberSearch";
export default {
  components: {
    WhitelistSidebar,
    MemberSearch,
  },
  data() {
    return {
      isSidebar: false,
      updateData: {},
      options: [
        {
          name: "Edit",
          icon: "EditIcon",
          svgClasses: "h-4 w-4",
        },
        {
          name: "Delete",
          icon: "TrashIcon",
          svgClasses: "h-4 w-4",
        },
      ],
      dataSize: 8,
      dataPage: 1,
      searchTerm: "",
      searchResults: [],
      searchKey: ["ipAddress", "createdBy"],
      filterOptions: [
        {
          value: false,
          text: "Deactivated",
        },
        {
          value: true,
          text: "Activated",
        },
        {
          value: null,
          text: "All",
        },
      ],
      filterBy: null,
    };
  },
  created() {
    this.$store.dispatch("whitelist/getIPs");
  },
  computed: {
    ...mapGetters("whitelist", {
      allWhitelistedIPs: "allWhitelistedIPs",
      activatedIPs: "activatedIPs",
      deactivatedIPs: "deactivatedIPs",
    }),
    optionName() {
      return (status) => {
        return status ? "Deactivate" : "Activate";
      };
    },
    dataCount() {
      let l = 0,
        s = this.dataSize;
      if (this.searchTerm.length > 1) {
        l = this.searchResults.length;
      } else {
        l = this.allWhitelistedIPs.length;
      }
      return Math.ceil(l / s);
    },
    tableData() {
      const start = (this.dataPage - 1) * this.dataSize,
        end = start + this.dataSize;
      if (this.searchTerm.length > 1) {
        return this.searchResults.slice(start, end);
      } else {
        if (this.filterBy == null) {
          return this.allWhitelistedIPs.slice(start, end);
        } else {
          if (this.filterBy) {
            return this.activatedIPs.slice(start, end);
          } else {
            return this.deactivatedIPs.slice(start, end);
          }
        }
      }
    },
  },
  destroyed() {
    this.$store.dispatch("whitelist/resetIPs");
  },
  methods: {
    handleAdd() {
      this.updateData = {};
      this.toggleWhitelistSidebar(true);
    },
    toggleWhitelistSidebar(val = false) {
      this.isSidebar = val;
    },
    handleOption(data, option) {
      if (option === "Edit") {
        this.handleEdit(data);
      }
      if (option === "Delete") {
        this.handleDelete(data);
      }
    },
    handleEdit(data) {
      this.updateData = {
        id: data._id,
        ip: data.ipAddress,
      };
      this.toggleWhitelistSidebar(true);
    },
    async handleDelete(data) {
      const params = data;
      const result = await this.confirmalert();
      if (result.value) {
        this.$vs.loading();
        const payload = {
          id: params._id,
        };
        const data = await this.$store.dispatch("whitelist/deleteIP", payload);
        if (data) {
          if (this.dataCount < 2) {
            this.dataPage = 1;
          }
          this.$vs.loading.close();
          await this.alert("success", "IP Deleted Successfully");
        }
      }
    },
    async handleStatus(data) {
      const params = data;
      const result = await this.confirmalert();
      if (result.value) {
        this.$vs.loading();
        const payload = {
          id: params._id,
          status: params.status ? false : true,
        };
        const data = await this.$store.dispatch(
          "whitelist/updateStatus",
          payload
        );
        if (data) {
          this.$vs.loading.close();
          // const success = await this.alert(
          //   "info",
          //   `IP ${params.status ? "Deactivated" : "Activated"} Successfully`
          // );
        }
      }
    },
    confirmalert() {
      return this.$swal({
        icon: "warning",
        title: "Are you sure ?",
        focusConfirm: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
      });
    },
    alert(type, text) {
      //       let timerInterval
      // Swal.fire({
      //   title: 'Auto close alert!',
      //   html: 'I will close in <b></b> milliseconds.',
      //   timer: 2000,
      //   timerProgressBar: true,
      //   onBeforeOpen: () => {
      //     Swal.showLoading()
      //     timerInterval = setInterval(() => {
      //       const content = Swal.getContent()
      //       if (content) {
      //         const b = content.querySelector('b')
      //         if (b) {
      //           b.textContent = Swal.getTimerLeft()
      //         }
      //       }
      //     }, 100)
      //   },
      //   onClose: () => {
      //     clearInterval(timerInterval)
      //   }
      // }).then((result) => {
      //   /* Read more about handling dismissals below */
      //   if (result.dismiss === Swal.DismissReason.timer) {
      //     console.log('I was closed by the timer')
      //   }
      // })
      return this.$swal({
        icon: type,
        title: text,
        showConfirmButton: false,
        timer: 1000,
      });
    },
    filterResults(result) {
      this.searchResults = result;
      if (this.searchResults.length > 0) {
        this.dataPage = 1;
      }
    },
  },
};
</script>
